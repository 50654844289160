import React from 'react';
import { H4 } from '@blueprintjs/core';
import { copyListStyles } from './copyList.styles';
import CopyListItem from './copyListItem';

const list = ({ items }) => {
  return (
    <React.Fragment>
      <H4>
        Found <em>{items.length}</em> {items.length > 1 ? 'copies' : 'copy'}
      </H4>
      <div className={copyListStyles}>
        {items.map(copy => (
          <CopyListItem key={copy.id} copy={copy} />
        ))}
      </div>
    </React.Fragment>
  );
};

export default list;
