import { put, takeEvery, all } from 'redux-saga/effects';
import { actionTypes } from '../actions/actionTypes';
import toastTypes from '../toastTypes';

function* toastErrorsCollection(action) {
  console.log("checking action: " + JSON.stringify(action));
  if(!action || !action.payload || !action.payload.response || !action.payload.response.errors)
    console.log("Payload: " + JSON.stringify(action.payload) + " | Response: " + JSON.stringify(action.payload.response) + " | Errors: " + JSON.stringify(action.payload.response.errors));
  const errors = action.payload && action.payload.response && action.payload.response.errors;
  if (errors.length) {
    yield all(
      errors.map(err =>
        put({ type: actionTypes.makeToast, text: err, title: 'Something went wrong', toastType: toastTypes.error })
      )
    );
  }
}

export default function* errorSaga() {
  yield takeEvery(actionTypes.checkInOut.getCopyStatusFailure, toastErrorsCollection);
  yield takeEvery(actionTypes.checkInOut.checkInFailure, toastErrorsCollection);
  yield takeEvery(actionTypes.checkInOut.checkOutFailure, toastErrorsCollection);
}
