import { RSAA } from 'redux-api-middleware';
import { actionTypes } from './actionTypes';

export const getRecentCheckouts = () => ({
  [RSAA]: {
    endpoint: () => `${API_URL.trim()}/checkouts/recentCheckouts/?numberOfResults=5`,
    method: 'GET',
    types: [
      actionTypes.recentCheckouts.getRecentCheckoutsRequest,
      actionTypes.recentCheckouts.getRecentCheckoutsReceive,
      actionTypes.recentCheckouts.getRecentCheckoutsFailure
    ]
  }
});
