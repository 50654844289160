import { put, select } from 'redux-saga/effects';
import { actionTypes } from '../actions/actionTypes';
import { getAllCheckouts } from '../actions/allCheckoutsActions';

const getSortAndOrder = (state) => ({
  resultsSort: state.allCheckouts.resultsSort,
  resultsOrder: state.allCheckouts.resultsOrder,
});

function* getAllCheckoutsBeeboo() {
  try {
    const { resultsSort, resultsOrder } = yield select(getSortAndOrder);

    yield put(getAllCheckouts(resultsSort, resultsOrder));
  } catch (error) {
    yield put({ type: actionTypes.allCheckouts.getAllCheckoutsFailure, error });
  }
}

export default getAllCheckoutsBeeboo;
