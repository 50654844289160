import { RSAA } from 'redux-api-middleware';
import { actionTypes } from './actionTypes';

export const getLongestCheckouts = () => ({
  [RSAA]: {
    endpoint: () => `${API_URL.trim()}/checkouts/checkedOutLongest/`,
    method: 'GET',
    types: [
      actionTypes.longestCheckouts.getLongestCheckoutsRequest,
      actionTypes.longestCheckouts.getLongestCheckoutsReceive,
      actionTypes.longestCheckouts.getLongestCheckoutsFailure
    ]
  }
});
