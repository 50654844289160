import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { formStyles } from './checkInOut.styles';
import CopyEntry from './copyEntry';
import AttendeeEntry from './attendeeEntry';
import OverridLimitCheckbox from './overrideLimitCheckbox';
import InOutButton from './inOutButton';
import PageBlock from '../pageBlock';
import { H3 } from '@blueprintjs/core';

const attendeeIdInputId = 'attendee-id';

const CheckInOut = () => {
  const checkboxValue = useSelector(state => state.checkInOut.overrideLimit);
  const [oldCheckboxValue, setOldCheckboxValue] = useState(checkboxValue);

  useEffect(() => {
    if (checkboxValue != oldCheckboxValue) {
      const input = document.getElementById(attendeeIdInputId);
      if (input) {
        input.focus();
      }
      setOldCheckboxValue(checkboxValue);
    }
  }, [checkboxValue]);

  return (
    <PageBlock squat={true}>
      <H3>Check In / Out</H3>
      <form className={formStyles} onSubmit={e => e.preventDefault()}>
        <CopyEntry/>
        <AttendeeEntry />
        <OverridLimitCheckbox />
        <InOutButton />
      </form>
    </PageBlock>
  );
};

export default CheckInOut;
