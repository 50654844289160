import React from 'react';
import { Intent, Tag, H5 } from '@blueprintjs/core';
import { CheckoutsListItem, datumStyles } from './checkoutsList.styles';

const getCheckoutListItem = checkout => {
  const attendee = checkout.attendee;
  const attendeeName = attendee.name;
  const attendeeBadgeNumber = attendee.badgeNumber;
  const gameTitle = checkout.copy.game.name;
  const copyId = checkout.copy.catalogID;
  const collectionName = checkout.copy.collection.name;
  const checkoutLengthDisplay = `${checkout.length.days}d ${checkout.length.hours}h ${checkout.length.minutes}m`;
  let intent = Intent.SUCCESS;
  if (checkout.length.hours >= 3) {
    intent = Intent.WARNING;
  }
  if (checkout.length.hours >= 5 || checkout.length.days > 0) {
    intent = Intent.DANGER;
  }

  return (
    <CheckoutsListItem key={checkout.id} intent={intent} icon={null}>
      <H5>
        {gameTitle} ({copyId}) <Tag>{collectionName}</Tag>
      </H5>
      <span className={datumStyles}>{checkoutLengthDisplay}</span>&middot;&nbsp;
      <span className={datumStyles}>
        {attendeeName} ({attendeeBadgeNumber})
      </span>
    </CheckoutsListItem>
  );
};

const list = ({ items }) => {
  return <React.Fragment>{items.map(copy => getCheckoutListItem(copy))}</React.Fragment>;
};

export default list;
