// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("content/Adlib-Regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("content/Adlib-Regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("content/Roboto-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("content/Roboto-Italic.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("content/Roboto-Black.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("content/Roboto-BlackItalic.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("content/Roboto-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("content/Roboto-BoldItalic.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_8___ = new URL("content/Roboto-Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_9___ = new URL("content/Roboto-LightItalic.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_10___ = new URL("content/Roboto-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_11___ = new URL("content/Roboto-MediumItalic.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_12___ = new URL("content/Roboto-Thin.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_13___ = new URL("content/Roboto-ThinItalic.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___);
var ___CSS_LOADER_URL_REPLACEMENT_11___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_11___);
var ___CSS_LOADER_URL_REPLACEMENT_12___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_12___);
var ___CSS_LOADER_URL_REPLACEMENT_13___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_13___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: ADLIB;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('woff2'), url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('woff');
}
@font-face {
  font-family: Roboto;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype'), url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('truetype'),
    url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('truetype'), url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format('truetype'),
    url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format('truetype'), url(${___CSS_LOADER_URL_REPLACEMENT_7___}) format('truetype'),
    url(${___CSS_LOADER_URL_REPLACEMENT_8___}) format('truetype'), url(${___CSS_LOADER_URL_REPLACEMENT_9___}) format('truetype'),
    url(${___CSS_LOADER_URL_REPLACEMENT_10___}) format('truetype'), url(${___CSS_LOADER_URL_REPLACEMENT_11___}) format('truetype'),
    url(${___CSS_LOADER_URL_REPLACEMENT_12___}) format('truetype'), url(${___CSS_LOADER_URL_REPLACEMENT_13___}) format('truetype');
}

body,
html,
#root {
  margin: 0;
  height: 100%;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;
}

h1,
h2,
h3 {
  font-family: ADLIB;
}

.bp3-callout h4.bp3-heading,
.bp3-callout h5.bp3-heading,
.bp3-callout h6.bp3-heading {
  margin: 0;
}
.bp3-heading {
  margin-bottom: 0;
}

.bp3-card {
  padding: 0.5em;
}

#toast-container {
  top: 0.75em;
}
`, "",{"version":3,"sources":["webpack://./src/style.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,oHAAqG;AACvG;AACA;EACE,mBAAmB;EACnB;;;;;4HAKwG;AAC1G;;AAEA;;;EAGE,SAAS;EACT,YAAY;EACZ;gCAC8B;AAChC;;AAEA;;;EAGE,kBAAkB;AACpB;;AAEA;;;EAGE,SAAS;AACX;AACA;EACE,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;AACb","sourcesContent":["@font-face {\n  font-family: ADLIB;\n  src: url(content/Adlib-Regular.woff2) format('woff2'), url(content/Adlib-Regular.woff) format('woff');\n}\n@font-face {\n  font-family: Roboto;\n  src: url(content/Roboto-Regular.ttf) format('truetype'), url(content/Roboto-Italic.ttf) format('truetype'),\n    url(content/Roboto-Black.ttf) format('truetype'), url(content/Roboto-BlackItalic.ttf) format('truetype'),\n    url(content/Roboto-Bold.ttf) format('truetype'), url(content/Roboto-BoldItalic.ttf) format('truetype'),\n    url(content/Roboto-Light.ttf) format('truetype'), url(content/Roboto-LightItalic.ttf) format('truetype'),\n    url(content/Roboto-Medium.ttf) format('truetype'), url(content/Roboto-MediumItalic.ttf) format('truetype'),\n    url(content/Roboto-Thin.ttf) format('truetype'), url(content/Roboto-ThinItalic.ttf) format('truetype');\n}\n\nbody,\nhtml,\n#root {\n  margin: 0;\n  height: 100%;\n  font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans',\n    'Helvetica Neue', sans-serif;\n}\n\nh1,\nh2,\nh3 {\n  font-family: ADLIB;\n}\n\n.bp3-callout h4.bp3-heading,\n.bp3-callout h5.bp3-heading,\n.bp3-callout h6.bp3-heading {\n  margin: 0;\n}\n.bp3-heading {\n  margin-bottom: 0;\n}\n\n.bp3-card {\n  padding: 0.5em;\n}\n\n#toast-container {\n  top: 0.75em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
