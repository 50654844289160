import { RSAA } from 'redux-api-middleware';
import { actionTypes } from './actionTypes';

export const checkStatus = () => ({
  [RSAA]: {
    endpoint: ({ checkInOut }) => `${API_URL.trim()}/copies/${checkInOut.copyId}`,
    method: 'GET',
    types: [
      actionTypes.checkInOut.getCopyStatusRequest,
      actionTypes.checkInOut.getCopyStatusReceive,
      actionTypes.checkInOut.getCopyStatusFailure
    ]
  }
});

export const checkOut = () => ({
  [RSAA]: {
    endpoint: ({ checkInOut }) => `${API_URL.trim()}/checkouts/CheckOut?catalogId=${checkInOut.copyId}&attendeeBadgeNumber=${checkInOut.attendeeId}&overrideLimit=${checkInOut.overrideLimit}`,
    method: 'GET',
    types: [actionTypes.checkInOut.checkOutRequest, actionTypes.checkInOut.checkOutReceive, actionTypes.checkInOut.checkOutFailure]
  }
});

export const checkIn = copyId => ({
  [RSAA]: {
    endpoint: `${API_URL.trim()}/checkouts/checkin/${copyId}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    types: [actionTypes.checkInOut.checkInRequest, actionTypes.checkInOut.checkInReceive, actionTypes.checkInOut.checkInFailure]
  }
});

export const updateCopyId = copyId => ({ type: actionTypes.checkInOut.updateCopyId, copyId });
export const updateAttendeeId = attendeeId => ({ type: actionTypes.checkInOut.updateAttendeeId, attendeeId });
export const toggleOverrideLimit = () => ({ type: actionTypes.checkInOut.toggleOverrideLimit });
export const reset = () => ({ type: actionTypes.checkInOut.reset });
