import { RSAA } from 'redux-api-middleware';
import { actionTypes } from './actionTypes';

export const toggleShowAllCheckouts = () => ({
  type: actionTypes.allCheckouts.toggleShowAllCheckouts
});

export const setSort = (sortKey) => (dispatch) => {
    return new Promise((resolve) => {
        dispatch({
        type: actionTypes.allCheckouts.setSort,
        payload: sortKey,
        });
        resolve();
    });
};

export const setOrder = (order) => (dispatch) => {
    return new Promise((resolve) => {
        dispatch({
            type: actionTypes.allCheckouts.setOrder,
            payload: order,
        });
        resolve();
    });
};

export const setLoading = (loading) => (dispatch) => {    
    return new Promise((resolve) => {
        dispatch({
            type: actionTypes.allCheckouts.setLoading,
            payload: loading
        });
        resolve();
    });
};

export const getAllCheckouts = (sort, order) => ({    
    [RSAA]: {
        endpoint: () => `${API_URL.trim()}/checkouts/allCheckouts/?sort=${sort.value}&order=${order}`,
        method: 'GET',
        types: [
            actionTypes.allCheckouts.getAllCheckoutsRequest,
            actionTypes.allCheckouts.getAllCheckoutsReceive,
            actionTypes.allCheckouts.getAllCheckoutsFailure
        ]
    }
});