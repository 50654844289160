import initialState from '../redux/initialState';
import { actionTypes } from '../actions/actionTypes';

const reducer = (state = initialState.allCheckouts, action) => {
  switch(action.type) {
    case actionTypes.allCheckouts.getAllCheckoutsReceive:
        return { ...state, results: action.payload.result, fetchingInitialResults: false };
    case actionTypes.allCheckouts.toggleShowAllCheckouts:
        return { ...state, showAllCheckouts: !state.showAllCheckouts };
    case actionTypes.allCheckouts.setSort:
        return { ...state, resultsSort: action.payload };
    case actionTypes.allCheckouts.setOrder:
        return { ...state, resultsOrder: action.payload };
        case actionTypes.allCheckouts.setLoading:
          return { ...state, fetchingInitialResults: action.payload };
    default:
        return state;
  }
};

export default reducer;