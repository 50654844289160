import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Callout, Intent, Tag, H6 } from '@blueprintjs/core';
import { updateCopyId } from '../../actions/checkInOutActions';
import { listItemStyles } from './copyList.styles';

const availableStatus = 'Available';

const copyListItem = ({ updateCopyId, copy }) => {
  const gameTitle = copy.game.name;
  const copyId = copy.catalogID;
  const collectionName = copy.collection.name;
  let status = availableStatus;
  if (copy.isCheckedOut) {
    const out = moment(copy.currentCheckout.timeOut);
    const outDisplay = out.format('ddd h:mm a');
    const attendee = copy.currentCheckout.attendee.name;
    status = `Borrowed ${outDisplay} by ${attendee}`;
  }

  const intent = status === availableStatus ? Intent.SUCCESS : Intent.DANGER;
  
  const handleItemClick = (event) => {
    if (event && event.nativeEvent && event.nativeEvent.type === 'click') {
      updateCopyId(copyId);
    }
  };

  return (
    <Callout key={copy.id} intent={intent} className={listItemStyles} onClick={handleItemClick}>
      <H6>
        {gameTitle} ({copyId}) <Tag>{collectionName}</Tag>
      </H6>
      {status}
    </Callout>
  );
};

const mapDispatchToProps = dispatch => ({
  updateCopyId: copyId => {
    dispatch(updateCopyId(copyId));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(copyListItem);