import { put, delay, all, fork, call } from 'redux-saga/effects';
import { getLongestCheckouts } from '../actions/longestCheckoutsActions';
import { getRecentCheckouts } from '../actions/recentCheckoutsActions';
import getAllCheckoutsBeeboo from './getAllCheckoutsSaga';

function* pollLongestCheckouts() {
  while (true) {
    yield delay(3000);
    yield put(getLongestCheckouts());
    yield delay(27000);
  }
}

function* pollRecentCheckouts() {
  while (true) {
    yield delay(3000);
    yield put(getRecentCheckouts());
  }
}

function* pollAllCheckouts() {
  while (true) {
    yield delay(3000);
    yield call(getAllCheckoutsBeeboo);
  }
}

export default function* checkoutsListsSaga() {
  yield all([
    fork(pollLongestCheckouts),
    fork(pollRecentCheckouts),
    fork(pollAllCheckouts)
  ]);
}
