import React from 'react';
import { createRoot } from 'react-dom/client';
import { Auth0ProviderWithNavigate } from './components/auth/auth0-provider-with-navigate';
import { BrowserRouter } from 'react-router-dom';
import App from './components/app';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '../node_modules/toastr/build/toastr.min.css';
import './style.css';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <Auth0ProviderWithNavigate>
      <App />
    </Auth0ProviderWithNavigate>
  </BrowserRouter>
);