import React from 'react';
import { connect } from 'react-redux';
import { Spinner, NonIdealState, H3, Button, Intent } from '@blueprintjs/core';
import CheckoutsList from './checkoutsList';
import Legend from './legend';
import { listBlockStyles, headerStyles, listBlockContentStyles } from './checkoutsLists.styles';
import PageBlock from '../pageBlock';
import { IconNames } from '@blueprintjs/icons';
import { toggleShowAllCheckouts } from '../../actions/allCheckoutsActions';

const checkoutsLists = ({ loadingLongest, longestCheckouts, loadingRecent, recentCheckouts, showAll, allCheckouts, loadingAll, dispatchToggle }) => {

  return (
    <PageBlock>
      <Legend />
      <div className={`${listBlockStyles}`}>
        <H3 className={headerStyles}>Recent Checkouts</H3>
        <div className={listBlockContentStyles}>{contentToLoad(loadingRecent, recentCheckouts)}</div>
      </div>
      <div className={`${listBlockStyles}`}>
        <H3 className={headerStyles}>Longest Checkouts</H3>
        <div className={listBlockContentStyles}>{contentToLoad(loadingLongest, longestCheckouts)}</div>        
        <Button style={{marginTop: '10px', marginBottom: '10px'}} intent={Intent.PRIMARY} onClick={dispatchToggle}>
          {showAll ? "Hide All" : "Show All"}
        </Button>
      </div>
    </PageBlock>
  );
};

const contentToLoad = (loading, results) => {
  if (loading) {
    return <Spinner />;
  }

  if (!results || !results.length) {
    const nisTitle = 'No checkouts found';
    const nisText = 'Nothing to show';
    return <NonIdealState title={nisTitle} description={nisText} icon={IconNames.CONFIRM} />;
  }

  return <CheckoutsList items={results} />;
};

const mapStateToProps = state => ({
  longestCheckouts: state.longestCheckouts.results,
  loadingLongest: state.longestCheckouts.fetchingInitialResults,
  recentCheckouts: state.recentCheckouts.results,
  loadingRecent: state.recentCheckouts.fetchingInitialResults,
  showAll: state.allCheckouts.showAllCheckouts,
  allCheckouts: state.allCheckouts.results,
  loadingAll: state.allCheckouts.fetchingInitialResults
});

const mapDispatchToProps = dispatch => ({
  dispatchToggle: () => dispatch(toggleShowAllCheckouts()) // <-- Dispatch the toggle action
});

export default connect(mapStateToProps, mapDispatchToProps)(checkoutsLists);
