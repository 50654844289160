import React from 'react';
import { connect } from 'react-redux';
import { checkoutGameStyles } from './copyEntry.styles';
import { checkStatus, updateCopyId, reset } from '../../actions/checkInOutActions';
import { Button, Intent, H5 } from '@blueprintjs/core';
import LabeledInput from '../shared/LabeledInput';
import { IconNames } from '@blueprintjs/icons';

const copyIdInputId = 'copy-id';
let typingTimer;

const CopyEntry = ({ copyId, checkingIn, gameTitle, updateCopyId, reset, refocusCopyId, checkStatus }) => {
  setTimeout(() => {
    const input = document.getElementById(copyIdInputId);
    if (refocusCopyId && input) {
      input.focus();
    }
  }, 50);

  // The pattern below matches 3-4 characters, a dash, 3-4 characters, a dash, and a final 3-4 characters.
  const pattern = /^[A-Za-z0-9]{3,4}-[A-Za-z0-9]{3,4}-[A-Za-z0-9]{3,4}$/;

  // Automatically fire off checkStatus if the value has not changed for half a second.
  const handleChange = (newText) => {
    updateCopyId(newText);
    
    clearTimeout(typingTimer);
    
    typingTimer = setTimeout(() => {
      if (pattern.test(newText)) {
        checkStatus(copyId);
      }
    }, 500);
  };

  return (
    <React.Fragment>
      {(!gameTitle || checkingIn) && (
        <LabeledInput
          label='Copy ID'
          allowWhitespace={false}
          inputId={copyIdInputId}
          autoFocus={true}
          value={copyId}
          onChange={newText => handleChange(newText)}
          placeholder='Scan Game Copy'
        />
      )}
      {gameTitle && !checkingIn && (
        <div className={checkoutGameStyles}>
          <H5>
            Game: <em>{gameTitle}</em>
          </H5>
          <Button minimal={true} icon={IconNames.UNDO} intent={Intent.DANGER} onClick={reset} />
        </div>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  copyId: state.checkInOut.copyId,
  autoSubmit: state.checkInOut.autoSubmit,
  gameTitle: state.checkInOut.gameTitle,
  checkingIn: state.checkInOut.checkingIn,
  refocusCopyId: !state.copySearch.searchText && state.copySearch.searchCompleted
});
const mapDispatchToProps = dispatch => ({
  reset: () => dispatch(reset()),
  checkStatus: () => {
    dispatch(checkStatus());
  },
  updateCopyId: copyId => {
    dispatch(updateCopyId(copyId));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CopyEntry);
