// eslint-disable-next-line no-unused-vars
import React from 'react';
import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { apiMiddleware } from 'redux-api-middleware';
import createSagaMiddleware from 'redux-saga';
import initialState from './initialState';
import apiAuthInjector from './apiAuthInjector';
import sagas from '../sagas/sagas';
import rootReducer from '../reducers/rootReducer';

export default function createReduxStore(getAccessTokenSilently) {
  const sagaMiddleware = createSagaMiddleware();

  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: { warnAfter: 512 },
        serializableCheck: { warnAfter: 512 },
      }).concat(thunk, apiAuthInjector(getAccessTokenSilently), apiMiddleware, sagaMiddleware),
    preloadedState: initialState
  });
  
  sagaMiddleware.run(sagas);

  return store;
}
