import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PageLoader } from './auth/page-loader';
import AuthenticationGuard from './auth/authentication-guard';
import { Provider } from 'react-redux';
import configureStore from '../redux/configureStore';
import LibrarianPage from './LibrarianPage';

const App = () => {
  const { isLoading, getAccessTokenSilently } = useAuth0();
    
  const store = configureStore(getAccessTokenSilently);
  
  if (isLoading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }

  return (
    <Provider store={store}>
      <Routes>
        <Route
          path='/'
          element={<AuthenticationGuard component={LibrarianPage} />}
        />
      </Routes>
    </Provider>
  );
};

export default App;