import { combineReducers } from 'redux';
import checkInOut from './checkInOutReducer';
import copySearch from './copySearchReducer';
import longestCheckouts from './longestCheckoutsReducer';
import recentCheckouts from './recentCheckoutsReducer';
import allCheckouts from './allCheckoutsReducer';

export default combineReducers({
  checkInOut,
  copySearch,
  longestCheckouts,
  recentCheckouts,
  allCheckouts
});