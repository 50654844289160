import React, { useState } from 'react';
import { connect } from 'react-redux';
import { attendeeEntryStyles } from './attendeeEntry.styles';
import { updateAttendeeId, checkOut } from '../../actions/checkInOutActions';
import LabeledInput from '../shared/LabeledInput';
import toastTypes from '../../toastTypes';
import { actionTypes } from '../../actions/actionTypes';

let typingTimer;
let inputValue;

const AttendeeEntry = ({ attendeeId, gameTitle, checkingIn, updateAttendeeId, copyId, overrideLimit, checkOut }) => {
  if (!gameTitle || checkingIn) {
    return '';
  }

  // Automatically fire off checkStatus if the value has not changed for half a second.
  const handleChange = (newText) => {
    updateAttendeeId(newText);

    clearTimeout(typingTimer);
    
    typingTimer = setTimeout(() => {
      inputValue = newText;
      checkOut();
    }, 2000);
  };

  return (
    <div className={`bp3-input-group ${attendeeEntryStyles}`}>
      <LabeledInput
        label='Attendee Badge #'
        inputId='attendee-id'
        autoFocus={true}
        value={attendeeId}
        allowWhitespace={false}
        onChange={newText => handleChange(newText)}
        placeholder='Scan Badge'
        disabled={checkingIn}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  copyId: state.checkInOut.copyId,
  overrideLimit: state.checkInOut.overrideLimit,
  attendeeId: state.checkInOut.attendeeId,
  gameTitle: state.checkInOut.gameTitle,
  checkingIn: state.checkInOut.checkingIn
});

const mapDispatchToProps = dispatch => ({
  updateAttendeeId: attendeeId => dispatch(updateAttendeeId(attendeeId)),
  checkOut: () => {
    if (!inputValue.trim()) {
      dispatch(updateAttendeeId(''));
      document.getElementById('attendee-id').focus();
      return dispatch(getInvalidIdAction('Bad Attendee Badge #', 'The attendee Badge # was out of range'));
    }
    dispatch(checkOut());
  }
});

const getInvalidIdAction = (title, text) => ({
  type: actionTypes.makeToast,
  title,
  text,
  toastType: toastTypes.warning
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AttendeeEntry);
