import { put, takeEvery } from 'redux-saga/effects';
import { actionTypes } from '../actions/actionTypes';
import { checkIn } from '../actions/checkInOutActions';

function* tryCheckIn(action) {
  if (action.payload.result.isCheckedOut) {
    yield put(checkIn(action.payload.result.id));
  }
}

function* handleCheckInSuccess(action) {
  const responseCopy = action.payload.result.copy;
  const collection = responseCopy.collection;
  const message = `
    ${responseCopy.game.name} was checked in <br /><br />
    <div style='text-align: center; width: 90%; font-weight: bold; background-color: ${collection.color};'>
        <span style='margin-right: 1em; font-size: 14pt;'>
          ${collection.name}
        </span>
    </div>`;
  yield put({ type: actionTypes.makeToast, text: message, title: 'Success' });
}

export default function*() {
  yield takeEvery(actionTypes.checkInOut.getCopyStatusReceive, tryCheckIn);
  yield takeEvery(actionTypes.checkInOut.checkInReceive, handleCheckInSuccess);
}
