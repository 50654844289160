import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Intent } from '@blueprintjs/core';
import { css } from '@emotion/css';

const styles = css`
  position: absolute;
  right: 1.2rem;
  top: 1rem;
`;

const LogoutButton = () => {
  const { logout } = useAuth0();

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  return (
    <Button  className={`${styles}`} intent={Intent.DANGER} onClick={handleLogout}>
      Logout
    </Button>
  );
};

export default LogoutButton;
