import { RSAA } from 'redux-api-middleware';
import { actionTypes } from './actionTypes';

export const searchCopies = () => ({
  [RSAA]: {
    endpoint: ({ copySearch }) => `${API_URL.trim()}/copies/search?query=${copySearch.searchText}`,
    method: 'GET',
    types: [actionTypes.copySearch.searchRequest, actionTypes.copySearch.searchReceive, actionTypes.copySearch.searchFailure]
  }
});

export const updateSearchText = text => ({ type: actionTypes.copySearch.updateCopySearchText, text });
