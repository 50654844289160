import { put, takeEvery } from 'redux-saga/effects';
import { actionTypes } from '../actions/actionTypes';

function* handleCheckOutSuccess(action) {
  const text = `${action.payload.result.copy.game.name} was checked out`;
  yield put({ type: actionTypes.makeToast, text, title: 'Success' });
}

export default function* checkoutSaga() {
  yield takeEvery(actionTypes.checkInOut.checkOutReceive, handleCheckOutSuccess);
}
