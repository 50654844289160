import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Spinner, NonIdealState, H3, H5, InputGroup, MenuItem, Button, Tooltip, Icon } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import CheckoutsList from './checkoutsLists/checkoutsList';
import { appStyles, allContentPaneStyles } from './app.styles';
import { listBlockStyles2, headerStyles, listBlockContentStyles, checkoutsContainer } from './checkoutsLists/checkoutsLists.styles';
import PageBlock from './pageBlock';
import { IconNames } from '@blueprintjs/icons';
import CopySearch from './copySearch/copySearch';
import CheckInOut from './checkInOut/checkInOut';
import CheckoutsLists from './checkoutsLists/checkoutsLists';
import Logout from './auth/logout';
import { getAllCheckouts, setSort, setOrder, setLoading } from '../actions/allCheckoutsActions';

const sortItems = [
    { label: "Recent", value: "recent" },
    { label: "Game Title", value: "gameTitle" },
    { label: "Attendee Name", value: "attendeeName" },
];
const orderItems = ['desc', 'asc'];

const LibrarianPage = ({ showAllComponents, loadingAll, allCheckouts, selectedSort, setSort, setOrder, setLoading, order }) => {

    const [filterText, setFilterText] = useState('');

    const onFilterTextChange = event => {
        if (event.target.value !== filterText) {
            setFilterText(event.target.value);
        }
    };
    
    const handleSelect = (item) => {
        setLoading(true);
        setSort(item);
        getAllCheckouts();
    };

    const toggleSortOrder = () => {
        let orderVal = order == 'desc' ? 'asc' : 'desc';
        setLoading(true);
        setOrder(orderVal);
        getAllCheckouts();
    };

    return (        
        <div className={appStyles}>
            <div className={allContentPaneStyles}>
            <CopySearch />
            <Logout />
            <CheckInOut />
            <CheckoutsLists />
            {showAllComponents && (
                <PageBlock>
                <div className={`${listBlockStyles2}`}>
                    <H3 className={headerStyles}>All Checkouts</H3>
                    <InputGroup
                        id='all-checkouts-search'
                        style={{width: '250px', marginBottom: '10px'}}
                        className='bp5-light'
                        placeholder='Title or Attendee Name/#'
                        autoFocus={false}
                        onChange={event => onFilterTextChange(event)}
                        rightElement={<Icon icon={IconNames.SEARCH} style={{marginTop: '6px', marginRight: '13px'}}/>}
                        type='search'
                    ></InputGroup>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                        <H5 style={{ paddingTop: '10px', marginRight: '10px', lineHeight: 'normal' }}>Sort by:</H5>
                        <Select
                            items={sortItems}
                            itemRenderer={(item, { handleClick, modifiers }) => (
                            <MenuItem
                                key={item.value}
                                text={item.label}
                                onClick={handleClick}
                                active={modifiers.active}
                            />
                            )}
                            onItemSelect={handleSelect}
                            filterable={false}
                        >
                            <Button
                            text={selectedSort ? selectedSort.label : "Select an option"}
                            rightIcon={IconNames.CHEVRON_DOWN}/>
                        </Select>
                        <Tooltip content={order == 'asc' ? "Ascending" : "Descending"}>
                            <Button
                                icon={order == 'asc' ? IconNames.SORT_ASC : IconNames.SORT_DESC} // Toggle icon
                                onClick={toggleSortOrder}
                                style={{ marginLeft: '10px', height: '32px', lineHeight: 'normal' }}
                                minimal // Optional: makes the button look flat
                            />
                        </Tooltip>
                    </div>
                    <div className={listBlockContentStyles}>{contentToLoad(loadingAll, allCheckouts, filterText)}</div>
                </div>
                </PageBlock>
            )}
            </div>
        </div>
    );
};

const contentToLoad = (loading, results, filterText) => {
  if (loading) {
    return <Spinner />;
  }

  if (!results || !results.length) {
    const nisTitle = 'No checkouts found';
    const nisText = 'Nothing to show';
    return <NonIdealState title={nisTitle} description={nisText} icon={IconNames.CONFIRM} />;
  }

  return <CheckoutsList items={results.filter(r => r.attendee.name.includes(filterText) || r.attendee.badgeNumber.includes(filterText) || r.copy.title.includes(filterText))} />;
};

const mapStateToProps = (state) => ({
    loadingAll: state.allCheckouts.fetchingInitialResults,
    allCheckouts: state.allCheckouts.results,
    showAllComponents: state.allCheckouts.showAllCheckouts,
    selectedSort: state.allCheckouts.resultsSort,
    order: state.allCheckouts.resultsOrder,
});

const mapDispatchToProps = (dispatch) => ({
    setSort: (sortType) => dispatch(setSort(sortType)),
    setOrder: (order) => dispatch(setOrder(order)),
    setLoading: (loadingVal) => dispatch(setLoading(loadingVal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LibrarianPage);