import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import authConfig from './auth0-variables';

export const Auth0ProviderWithNavigate = ({ children }) => {
  const navigate = useNavigate();

  const domain = authConfig.domain;
  const clientId = authConfig.clientId;
  const redirectUri = authConfig.callbackUrl;
  const audience = authConfig.apiIdentifier;
  const scope = 'openid read:recent-checkouts read:longest-checkouts create:checkout update:checkout read:copy-search read:copy';

  const onRedirectCallback = async (appState) => {
     navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: redirectUri,
        scope: scope,
        audience: audience
      }}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};